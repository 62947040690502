import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { BotModel, CorpModel, HierarchyElementModel } from 'src/app/models';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { SidebarService } from 'src/app/services/sidebar.service';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { log } from 'console';

@Component({
  selector: 'app-bot-knowledge-bank',
  templateUrl: './bot-knowledge-bank.component.html',
  styleUrls: ['./bot-knowledge-bank.component.scss'],
})
export class BotKnowledgeBankComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  isLoading: boolean = true;
  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;
  bot: BotModel;
  dealerId: string = '101001000';

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
    private corpsService: CorpsService,
    private hierarchyElementsService: HierarchyElementsService,
    private botsService: BotsService,
    private clientEnvironmentService: ClientEnvironmentService,
    private authService: AuthService,
    private toaster: ToastrService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.activatedRoute.paramMap, this.authService.currentUser, this.clientEnvironmentService.items$]).subscribe(
      ([params, user, envs]) => {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        const botCode = params.get('bot');
        if (!corpId || !user || !hierarchyElementSystemName || !botCode || !envs) {
          return;
        }

        this.isLoading = true;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
          this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
        ]).subscribe(
          ([corp, hierarchyElement, bot]) => {
            if (!corp || !hierarchyElement || !bot) {
              this.isLoading = false;
              return;
            }

            this.corp = corp;
            this.hierarchyElement = hierarchyElement;
            this.bot = bot;

            this.refreshUI();

            this.isLoading = false;
          },
          error => {
            this.isLoading = false;
            this.toaster.error(error);
          },
        );
      },
    );
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }

  private refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Knowledge Bank', 'knowledge-bank'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }
}
